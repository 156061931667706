
  
  /* table */
  .ant-table {
    font-size: 15px;
  }
  
  /* row data */
  .ant-table-tbody > tr > td {
    height: 10px;
    padding: 8px;
  }

  .ant-table-thead > tr > th {
    height: 10px;
    padding:4px;
    font-size: 1rem;
    align-items:flex-end;
    gap: "0.5rem";
    width: "6rem";
    color: #696969;
    padding-top: "0.5rem",
    
  }
  